import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Helmet from "react-helmet";
import Production from '../components/Production';

const ProductionPage = ({ data }) => {
    const { markdownRemark: { frontmatter } } = data
    const {
        seo,
        title,
        image,
        categorie,
        description,
        duration,
        year,
        language,
        director,
        writers,
        casting,
        series,
        episodes,
        actors,
    } = frontmatter
    return (
        <Layout>
            <Helmet
                title={seo.seo_title}
                meta={[
                    {
                        name: "description",
                        content: seo.seo_metta_desc
                    }
                ]}
            />
            <Production 
                title={title}
                image={image}
                categorie={categorie}
                description={description}
                duration={duration}
                year={year}
                language={language}
                director={director}
                writers={writers}
                casting={casting}
                series={series}
                episodes={episodes}
                actors={actors}
            />
        </Layout>
    )
}

export default ProductionPage
export const productionPageQuery = graphql`
query ProductionPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
        frontmatter{
            seo{
                seo_title
                seo_metta_desc
            }
            title
			image
			categorie
			description
            duration
            year
            language
            director
            writers{
                name
            }
            casting
            series
            episodes
            actors{
                name
                photo
            }
        }
    }
    }
`