import React from 'react'

const ProductionInfo = ({
    categorie,
    duration,
    year,
    language,
    director,
    writers,
    casting,
    series,
    episodes,
}) => {
    return (
        <div>
            <h5>Catégorie: <span> {categorie} </span></h5>
            <h5>Durée:  <span> {duration} </span></h5>
            <h5>Année de sortie:  <span> {year} </span></h5>
            <h5>Langue: <span> {language} </span></h5>
            <h5>Réalisé par:  <span> {director} </span></h5>
            <h5>Scénaristes:
            {writers.map((item, index) => {
                return (
                    <span key={index}> {item.name} </span>
                )
            })}
            </h5>
            <h5>Casting:  <span> {casting} </span></h5>
            {(series && series !== "") && <h5>Séries: <span> {series} </span></h5>}
            {(episodes && episodes !== "") && <h5>Episodes:  <span> {episodes} </span></h5>}
        </div>
    )
}
export default ProductionInfo