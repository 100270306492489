import React, { Component } from 'react'
import styles from './production.module.css'
import ProductionInfo from './helpers/ProductionInfo';
import { cutString } from '../Comon/utils'
import { Embed } from 'semantic-ui-react';

class Production extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showAll: false
        }
        this.toogleShowAllText = this.toogleShowAllText.bind(this)
    }
    toogleShowAllText() {
        const { showAll } = this.state
        this.setState({
            showAll: !showAll
        })
    }
    render() {
        const {
            title,
            image,
            categorie,
            description,
            duration,
            year,
            language,
            director,
            writers,
            casting,
            series,
            episodes,
            actors,
        } = this.props
        const { showAll } = this.state
        return (
            <div>
                <section className={`header-parallax ${styles.hero}`} style={{ backgroundImage: `url(${image})` }}>
                    <div className="container">
                        <h1>{title}</h1>
                    </div>
                </section>
                <section className={`container ${styles.info_section}`}>
                    <div className='ui stackable two column grid'>
                        <div className="column">
                            <h4>Description</h4>
                            <p>{(showAll) ? description : cutString(description, 100)}<span onClick={this.toogleShowAllText}> voir {(showAll) ? 'moins' : 'plus'}</span></p>
                            <h4>Bande annonce</h4>
                            <Embed id='O6Xo21L0ybE' placeholder={image} source='youtube' />
                        </div>
                        <div className="column">
                            <ProductionInfo
                                categorie={categorie}
                                duration={duration}
                                year={year}
                                language={language}
                                director={director}
                                writers={writers}
                                casting={casting}
                                series={series}
                                episodes={episodes}
                            />
                        </div>
                    </div>
                </section>
                <section className={`container ${styles.actors_section}`}>
                    <h1>Participants</h1>
                    <div className="ui stackable three column grid">
                        {
                            actors.map((item, index) => {
                                return (
                                    <div key={index} className={`column ${styles.image_container}`}>
                                        <img src={item.photo} alt={item.name}/>
                                    </div>
                                )
                            })
                        }
                    </div>
                </section>
            </div>
        )
    }
}
export default Production